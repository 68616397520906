import React from "react"
import Layout from "./../components/layout"
import LandingTop from "../components/landingTop"
import LandingDiscover from "../components/landingDiscover"
import MenuComponent from "../components/menuComponent"
import MainGallery from "../components/mainGallery"
import ReservationComponent from "../components/reservationComponent"


const IndexPage = () => <Layout>
  <LandingTop/>
  <LandingDiscover/>
  <MenuComponent/>
  <ReservationComponent/>
  <MainGallery/>
</Layout>

export default IndexPage;
