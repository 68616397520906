import React from "react"
import * as R from "ramda"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const mapIndexed = R.addIndex(R.map);

const imagesQuery = graphql`
    query GalleryQuery {
        galleryImages: allFile(filter: {relativeDirectory: {eq: "mainGallery"}}) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, width: 1240, placeholder: BLURRED)
                    }
                }
            }
        }
    }
`

const MainGallery = () => {
  const { galleryImages } = useStaticQuery(imagesQuery)
  const photos = R.map(x => {
    return { id: x.node.id, image: getImage(x.node.childImageSharp), key: x.node.id }
  }, galleryImages.edges)

  return <section className="section section_gallery">
    <div className="container">
      <div className="row">
        <div className="col">

          <h2 className="section__heading text-center">
            Our gallery
          </h2>

          <p className="section__subheading text-center">
            When you will visit El Humero, you will feel that you have magically been transported to Colombia.
          </p>

        </div>
      </div>
      <div className="row gallery">
        {
          mapIndexed((x,i) => {
            return <GatsbyImage key={i} alt="El Humero" image={x.image}/>
          }, photos)
        }
      </div>
    </div>
  </section>

}

export default MainGallery
