import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const StyledParagraph = styled.p`
color: #003893;
`

const imagesQuery = graphql`{
  casa: file(relativePath: {eq: "Casa.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 300, layout: CONSTRAINED)
    }
  }
  chiva: file(relativePath: {eq: "Chiva.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, width: 300, layout: CONSTRAINED)
    }
  }
}
`

const LandingDiscover = () => {
  const data = useStaticQuery(imagesQuery)

  return <>
    <section className="section section_discover section_no-padding_bottom">
      <Container>
        <Row>
          <Col md={2} className="align-self-start">
            <StyledParagraph className="section_discover__description">
              El Humero is the first traditional Colombian-style barbecue restaurant in New Zealand.
            </StyledParagraph>
          </Col>
          <Col md={4} className="align-self-start">
            <div className="section_discover__img">
              <GatsbyImage
                image={data.casa.childImageSharp.gatsbyImageData}
                className="img-fluid"
                alt="Casa" />
            </div>
          </Col>
          <Col md={6} className="align-self-center">
            <h2><em className="text-danger">Colombia</em> our story</h2>
            <p>
              We deliver a culinary experience that showcases the classic and rustic elements of Colombia. When you will
              visit El Humero, you will feel that you have magically been transported to Colombia through our
              mouth-watering Colombian-style barbecue dishes prepared fresh over traditional wood-fired and charcoal
              grills.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="section section_discover">
      <Container>
        <Row>
          <Col md={2} className="order-md-2 align-self-end">
            <StyledParagraph className="section_discover__description">
              We promise that you will walk away with a lasting taste of our food and the warmth of the
              Colombian people.
            </StyledParagraph>
          </Col>
          <Col md={4} className="order-md-3 align-self-start">
            <div className="section_discover__img alt">
              <GatsbyImage
                image={data.chiva.childImageSharp.gatsbyImageData}
                className="img-fluid"
                alt="Chiva" />
            </div>
          </Col>
          <Col md={6} className="order-md-1 align-self-center">
            <h2><em className="text-danger">Our home</em> is your home</h2>
            <p>
              We promise that you will walk away with a lasting taste of our food and the warmth of the
              Colombian people.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  </>;
}

export default LandingDiscover
