import React from "react"
import { Col, Container, Row } from "reactstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const imagesQuery = graphql`{
    bbq: file(relativePath: {eq: "bbq.jpg"}) {
        childImageSharp {
            gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
        }
    }
}
`

const LandingTop = () => {
  const { bbq } = useStaticQuery(imagesQuery)
  const img = getImage(bbq)
  return (
    <div style={{display: "grid", height: "100vh"}}>
      <GatsbyImage style={{gridArea : "1/1"}} alt="El Humero" image={img}/>
      <div style={{
        gridArea: "1/1",
        position: "relative",
        display: "grid",
      }}>
        <div className="section_welcome__main">
          <Container>
            <Row className="align-items-center">
              <Col>
                <h2 className="section__preheading section_welcome__preheading text-center text-muted">
                  <span className="text-primary">Colombian</span> Barbecue
                </h2>
                <h1 className="section__heading section_welcome__heading text-center">
                  El Humero
                </h1>
                <p className="section__subheading section_welcome__subheading text-center text-muted">
                  Traditional Colombian-Style Barbecue
                </p>
                <div className="text-center">
                  <Link to="/#section_reservation" className="btn btn-primary text-white">Make Reservation</Link>
                </div>
                <div className="text-center mt-2">
                  <a href="https://order.elhumero.co.nz/" className="btn btn-primary text-white">Takeaway Orders</a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section_welcome__footer">
          <Container>
            <Row className="align-items-center">
              <Col>
                <ul className="section_welcome__footer__social text-center text-lg-left">
                  <li>
                    <a href="https://twitter.com/ElHumeroAkl">
                      <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/elhumeroakl">
                      <FontAwesomeIcon icon={faFacebookF}/>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/elhumeroakl/">
                      <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className="section_welcome__footer__address text-center text-lg-right">
                  <i className="fas fa-map-marker-alt"/> 3/40 Hurstmere Road, Takapuna, Auckland, New Zealand
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}


export default LandingTop
